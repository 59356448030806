'use client';

import axiosClient from '@/helper/call-center';
import { useQuery } from '@tanstack/react-query';

const FloatingAction = () => {
  const location = typeof window !== 'undefined' ? localStorage.getItem('location_id') : null;

  const userQuery = useQuery({
    queryKey: ['location-detail'],
    queryFn: async () => {
      try {
        const result: any = await axiosClient.get(`store-branch/${location}`);

        return result;
      } catch (error) {
        return [];
      }
    },
    refetchOnWindowFocus: false,
  });

  const telByShop: any = userQuery?.data?.data?.phone;

  return (
    <div className='group fixed bottom-2 right-0 !z-[9999] flex flex-col items-end space-y-3 p-2'>
      {/* <TawkToChat /> */}

      <a
        data-track-id='tracking-phone'
        href={`tel:${telByShop}`}
        target='_blank'
        rel='noopener noreferrer'
        className='flex size-12 cursor-pointer items-center justify-center rounded-full bg-[#D29A2A] p-2 text-white shadow-sm shadow-zelene-grey transition-all ease-out hover:p-3 hover:text-white sm:h-auto sm:w-auto'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-phone'
        >
          <path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z' />
        </svg>
      </a>
    </div>
  );
};

export default FloatingAction;
