'use client';

import { membershipCategoryApi } from '@/api-client/membership-category';
import { useRouter } from '@/hooks/useRouter';
import { useMembershipAction } from '@/store/membershipOpen';
import { imgUrlTransferV1 } from '@/utils/global-func';
import { useQuery } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import { useState } from 'react';

export const items = [
  {
    title: 'ESSENTIAL',
    price: 'from $99',
    bgClass: 'bg-product-card1',
    isBestSeller: false,
  },
  {
    title: 'PREMIUM',
    price: 'from $149',
    bgClass: 'bg-product-card2',
    isBestSeller: false,
  },
  {
    title: 'ELITE',
    price: 'from $199',
    bgClass: 'bg-product-card3',
    isBestSeller: false,
  },
  {
    title: 'FAMILY & FRIEND',
    price: 'from $299',
    bgClass: 'bg-product-card4',
    isBestSeller: true,
  },
  // Add more items as needed
];

interface IMembershipCard {
  color?: boolean;
  loading: boolean;
}

const MembershipCarousel = (props: IMembershipCard) => {
  const { setMembershipOpen } = useMembershipAction();
  const { color } = props;
  const router = useRouter();
  const pathname = usePathname();

  const [locationLocal, setLocationLocal] = useState(
    typeof window !== 'undefined' ? localStorage.getItem('location_id') : null,
  );

  const policyQuery = useQuery({
    queryKey: ['policy', { active: 'true' }],
    queryFn: () => membershipCategoryApi.paginate({ active: 'true' }),
  });
  // Filter categories: only active categories that match the current location
  const dataCate =
    policyQuery.data?.data?.categories?.filter(category => {
      if (!category.active) return false;

      // If no location is set, return all active categories
      if (!locationLocal) return true;

      // Check if the category applies to the current location
      const branchIds = category.branchesApply.map(item => item._id);
      return branchIds.includes(locationLocal);
    }) || [];

  if (policyQuery.isLoading) {
    return <div>Loading...</div>;
  }

  // const settings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   draggable: true, // Enable dragging
  //   responsive: [
  //     { breakpoint: 1024, settings: { slidesToShow: 3 } },
  //     { breakpoint: 768, settings: { slidesToShow: 1 } },
  //   ],
  // };
  const handleClick = () => {
    if (pathname === '/membership') {
      return;
    }
    router.push('/membership');
  };

  const trackingColor = (title: string) => {
    if (color) {
      switch (title) {
        case 'ESSENTIAL':
          return '#D29A2A';
        case 'PREMIUM':
          return '#1D3F75';
        case 'ELITE':
          return '#102647';
        case 'FAMILY & FRIEND':
          return 'black';
      }
    }

    return '#fff';
  };

  return (
    <div className='mx-auto mt-6 w-full max-w-screen-2xl px-6 sm:px-12'>
      {/* <Carousel {...settings}>
         
        </Carousel> */}
      <div className='grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4'>
        {dataCate?.map((item, index) => (
          <div key={index} className='flex justify-center' onClick={handleClick}>
            <div className='flex flex-1 flex-col items-center justify-center gap-2'>
              <div
                onClick={() => setMembershipOpen(true)}
                className={`relative min-h-[186px] w-full cursor-pointer rounded-[44px] !border-2 !border-[#FCDCAB] bg-slate-200 bg-cover bg-center bg-no-repeat sm:rounded-3xl md:h-[500px] md:max-w-[300px]`}
                style={{ backgroundImage: `url(${imgUrlTransferV1(item.image)})` }}
              >
                {item.sort_name === 'ELITE' && <div className='relative -right-0 -top-12 size-20'></div>}
                {
                  item.tag && (
                    <div className='absolute right-4 top-11 -translate-y-1/2 translate-x-1/2 sm:right-0 sm:top-0'>
                      {/* <ComponentBestSeller tag={item.tag} /> */}

                      <div
                        className='inline-flex h-[70px] w-[70px] items-center justify-center rounded-full border-4 border-[#1D3F75] bg-cover bg-center sm:h-[100px] sm:w-[100px]'
                        style={{
                          backgroundImage: "url('/images/Home/image_best_seller.png')",
                        }}
                      >
                        <span className='text text-center font-prettywise text-sm font-medium uppercase text-white sm:text-xl'>
                          {item.tag}
                        </span>
                      </div>
                    </div>
                  ) // Display the best seller badge
                }
              </div>
              <span
                style={{ color: color ? '#102647' : '#fff', fontWeight: color ? 700 : 500 }}
                className='pt-2 font-prettywise text-lg font-medium leading-[21px] [text-shadow:0px_4px_14px_rgba(0,_0,_0,_0.20)]'
              >
                {item.sort_name}
              </span>
              <span
                style={{ color: color ? '#1D3F75' : '#fff' }}
                className='pt-1 font-hneu text-base font-light leading-[21px] [text-shadow:0px_4px_14px_rgba(0,_0,_0,_0.20)]'
              >
                ${item.policy.price}/{item.policy.days_count === 30 ? 'month' : 'year'}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MembershipCarousel;
