import useMobileScreen from '@/hooks/useMobileScreen';
import { useRouter } from 'next/navigation';
import BuyNowBtn from '../Slider2/BuyNowBtn';

interface Props {
  title: string;
  subtitle: string;
  description: string;
  linkMobile?: string;
  linkDesktop?: string;
  linkCta: string;
  textCta: string;
}

export default function HeroCarouselSlideV2(props: Props) {
  const { title, subtitle, description, linkMobile, linkDesktop, linkCta, textCta } = props;
  const router = useRouter();
  const isMobile = useMobileScreen();
  // const [loaded, setLoaded] = useState(false);

  if (isMobile) {
    // 48 auto-slide
    // 92 header mobile
    // 40 section 1
    return (
      <div className='h-[calc(100vh-180px)]'>
        <div className='relative h-full overflow-hidden !bg-[#122A4E25] sm:h-svh'>
          {/* Video */}
          <video
            preload='auto'
            autoPlay
            loop
            muted
            playsInline
            poster='/videos/hero-section/Mobile-View-Review.jpg'
            className={`absolute inset-0 h-full w-full object-cover transition-opacity duration-500`}
            // onLoadedData={() => setLoaded(true)}
            style={{ aspectRatio: '16/9' }} // Đảm bảo tỷ lệ khung hình
          >
            <source src={linkMobile} type='video/mp4' />
            Your browser does not support the video tag.
          </video>

          <div className='absolute z-50 flex h-full w-full flex-col items-center justify-center font-prettywise font-medium text-zelene-dark-blue'>
            <p className='mb-2 font-hneu text-base font-bold text-white lg:text-lg'>{subtitle}</p>
            <h1 className='!-mb-2 text-center font-prettywise text-7xl font-semibold text-white'>{title}</h1>

            <div className='mb-0 mt-2 max-w-[350px] text-center font-hneu text-sm font-[500px] text-zelene-light-grey lg:text-lg'>
              {description}
            </div>

            <BuyNowBtn routePath={linkCta} buttonText={textCta} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='h-[calc(100vh-180px)] !bg-[#122A4E25] sm:!h-[calc(80vh-48px)]'>
      <div className='relative -mb-[190px] h-[100%] overflow-hidden !bg-[#122A4E25] sm:-mb-[170px] sm:h-svh'>
        <video
          poster='/videos/hero-section/Destop-View-Review.jpg'
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: 'absolute',
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 1,
          }}
        >
          <source src={linkDesktop} type='video/mp4' />
          Your browser does not support the video tag.
        </video>

        <div className='absolute z-50 flex !h-[calc(70vh)] w-full flex-col items-center justify-center font-prettywise font-medium text-zelene-dark-blue'>
          <p className='mb-0 font-hneu text-base font-bold text-white lg:text-lg'>{subtitle}</p>
          <div className='mb-0 text-center font-prettywise text-7xl font-semibold text-white'>{title}</div>

          <div className='z-30 flex w-full items-center justify-center gap-6'>
            {/* <Image
              src={Pattern}
              alt='background image'
              width={70}
              height={70}
              className='!size-[62px] object-contain font-prettywise text-[128px] sm:!size-[60px] lg:!size-[70px]'
            /> */}

            {/* <div className='font-prettywise text-7xl leading-normal text-white'>{title2}</div> */}

            {/* <Image
              src={WhiteStar}
              alt='background image'
              width={30}
              height={30}
              className='!size-[62px] object-contain font-prettywise text-[128px] md:!size-[30px]'
            /> */}
          </div>

          <div className='mb-0 mt-2 max-w-[600px] px-6 text-center font-hneu text-sm font-[500px] text-zelene-light-grey sm:px-0 lg:text-lg'>
            {description}
          </div>

          <BuyNowBtn routePath={linkCta} buttonText={textCta} />
        </div>
      </div>
    </div>
  );
}
