'use client';

import CartList from '@/components/elements/Cart/CartList';
import { LoadingButton } from '@/components/ui/loadding-button';
import { useAuth } from '@/hooks/auth.hook';
import { useCart } from '@/hooks/cart.hook';
import { useRouter } from '@/hooks/useRouter';
import { useWindowScroll } from '@/hooks/useWindowScroll';
import { locationsMenuMock } from '@/mocks/location-data';
import { Button, Drawer, Popover } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';
import { TfiMenu } from 'react-icons/tfi';
import styled from 'styled-components';
import LogoHeader from '../../../../public/images/Common/header-logo.svg';
import yellowStar from '../../../../public/images/yellowStar.png';
import CartICon from '../../../../public/svgs/cart-icon.svg';
import LocationDropdown from './LocationDropdown';
import Menu from './Menu';
import { HomeAuthAction } from './auth-action';

interface IClientHeaderV2Props {
  className?: { root?: any };
}

const CustomPopover = styled(Popover)`
  .ant-popover-inner {
    padding: -12px !important;
  }
`;

const ClientHeaderV2 = (props: IClientHeaderV2Props) => {
  const [open, setOpen] = React.useState(false);
  const [cartOpen, setCartOpen] = React.useState(false);
  const [submenuOpen, setSubmenuOpen] = React.useState(false);
  const [scroll] = useWindowScroll();
  const ref = React.useRef(null);
  const router = useRouter();
  const { cartMemberQuery, cartGuestQuery, deleteCartGuestMutation, deleteCartMemberMutation } = useCart();
  const pathName = usePathname();

  const toggleMenu = () => {
    setOpen(!open); // Toggle between open and closed
  };
  const toggleCart = () => {
    setCartOpen(!cartOpen);
  };
  const toggleSubmenu = () => setSubmenuOpen(!submenuOpen);

  const activeStyle =
    'py-1 rounded-tl-none rounded-br-none rounded-tr-none rounded-bl-[.75rem] bg-[linear-gradient(270deg,_rgba(255,_255,_255,_0.00)_0%,_rgba(252,_220,_171,_0.50)_50%)] *:!text-zelene-red !text-zelene-red';

  //For Cart Feature
  const { profile } = useAuth();
  const listCart = profile ? cartMemberQuery?.data?.data : cartGuestQuery?.data?.data;
  const totalServicesCount = listCart?.length;
  const totalAddonsCount = listCart?.reduce((acc: any, item: any) => acc + item?.addons?.length, 0);
  const totalPrice = listCart?.reduce((total: any, service: any) => {
    const servicePrice = parseFloat(service?.service?.price);
    const addonsTotal = service?.addons?.reduce(
      (addonTotal: any, addon: any) => addonTotal + parseFloat(addon?.price),
      0,
    );
    return total + servicePrice + addonsTotal;
  }, 0);

  return (
    <div className='relative !z-[999] mx-auto h-full w-full' ref={ref}>
      {/* <div
        id='text'
        className='z-[10] mx-auto flex h-12 w-[90%] items-center justify-center whitespace-nowrap bg-zelene-blue'
      >
        <span>
          <a href='/booking' className='text-white underline'>
            Prebook Fort Worth location now for 20% off in April!
          </a>
        </span>{' '}
      </div> */}

      <div
        className={clsx(
          '!z-[999] flex w-full flex-col justify-center bg-[linear-gradient(0deg,_rgba(255,_249,_239,_0.90)_0%,_rgba(254,_241,_221,_0.90)_100%)] transition-all sm:pt-4',
          scroll.y > 0 &&
            '!bg-[linear-gradient(0deg,_rgba(255,_249,_239,_0.90)_0%,_rgba(254,_241,_221,_0.90)_100%)] !shadow-2xl transition-all',
          props.className?.root,
        )}
      >
        <div className='mx-auto w-[100%] px-6 sm:w-[90%] sm:px-0'>
          <div id='header' className='relative flex h-[5.75rem] items-center justify-between sm:mt-1 sm:h-28'>
            <div className='order-2 w-fit sm:order-1'>
              <LocationDropdown
                className={{
                  root: '!border-[#fcdbab] !bg-[#FFF7EB] !pb-0 sm:rounded-[.6875rem] sm:border sm:px-3 sm:py-1.5',
                  text: 'text-zelene-dark-blue',
                  colorLocationICon: '!text-zelene-blue',
                }}
              />
            </div>

            <div
              id='logo'
              className='relative order-1 h-[3.75rem] w-[3.3125rem] sm:order-2 sm:hidden sm:h-full sm:w-[6.25rem]'
            >
              <Image
                src={LogoHeader}
                alt='logo header'
                fill
                sizes='7.5rem'
                className='cursor-pointer object-contain'
                onClick={() => router.push('/')}
              />
            </div>

            <div
              id='logo'
              className='absolute left-1/2 order-1 hidden h-[3.3125rem] w-[3.125rem] -translate-x-1/2 -translate-y-[17%] sm:order-2 sm:h-full sm:w-[5.4375rem] lg:block'
            >
              <div className='relative h-full w-full'>
                <Image
                  src={LogoHeader}
                  alt='logo header'
                  fill
                  sizes='7.5rem'
                  className='cursor-pointer object-contain'
                  onClick={() => router.push('/')}
                />
              </div>
            </div>

            <div className='order-3 flex items-center justify-between gap-6'>
              <Button
                data-track-id='tracking-franchise'
                onClick={() => router.push('/franchise')}
                styles={{ icon: { order: 2 } }}
                className='!z-[70] !hidden !h-[2.5rem] cursor-pointer !rounded-xl !border-none !bg-[linear-gradient(180deg,_#102647_0%,_#1D3F75_100%)] !px-6 !font-hneu !text-base !font-medium !text-white hover:!bg-[#4674C6] sm:!block'
              >
                Own a Franchise
              </Button>

              <Button
                data-track-id='step1_location'
                onClick={() => {
                  router.push('/booking');
                }}
                styles={{ icon: { order: 2 } }}
                className={clsx(
                  '!z-[70] !hidden !h-[2.5rem] cursor-pointer !rounded-xl !border-none !px-6 !font-hneu !transition-all hover:!border-zelene-red sm:!flex',
                  '!bg-gradient-to-r from-[#B84E44] to-[#B84E44CC]',
                  '!text-base !font-medium !text-white',
                )}
              >
                Book Now
                <Image width={16} height={16} src={yellowStar} alt='arrow right' className='object-contain' />
              </Button>

              <div className='flex items-center gap-3 *:cursor-pointer sm:gap-4'>
                <HomeAuthAction />
                <div onClick={toggleCart}>
                  <Image
                    src={'/svgs/cart-icon.svg'}
                    alt='cart icon'
                    width={20}
                    height={20}
                    className='m-[.375rem] size-6 object-contain'
                  />
                </div>

                <div onClick={toggleMenu} className='lg:!hidden'>
                  <TfiMenu className={clsx('size-6 object-contain !text-[#1D3F75]')} />
                </div>
              </div>
            </div>
          </div>

          <nav id='list-menu' className='h-13 hidden pb-1 sm:block'>
            <div className='hidden !items-center !justify-center gap-4 *:text-[1.125rem] *:font-medium *:leading-[1.5263rem] *:text-[#102647] lg:flex'>
              <Link
                className={clsx(
                  'flex items-center gap-1.5 px-3 hover:!text-zelene-red',
                  pathName === '/about' && activeStyle,
                )}
                href={'/about'}
              >
                <span className='!text-zele-red hover:text-zelene-red'>About Us</span>
                <Image alt='star footer' width={16} height={16} src={yellowStar} className='h-[1rem] w-[1rem]' />
              </Link>

              <Link
                href={'/membership'}
                className={clsx(
                  'flex items-center gap-1.5 px-3 hover:!text-zelene-red',
                  pathName === '/membership' && activeStyle,
                )}
              >
                <span className='!text-zele-red'>Membership</span>
                <Image alt='star footer' width={16} height={16} src={yellowStar} className='h-[1rem] w-[1rem]' />
              </Link>

              <Link className={clsx('px-3', pathName === '/gift' && activeStyle)} href={'/gift'}>
                <span className='!text-zele-red hover:text-zelene-red'>Gift Card</span>
              </Link>

              <Link className={clsx('px-3', pathName.includes('/services') && activeStyle)} href={'/services'}>
                <span className='!text-zele-red hover:text-zelene-red'>Services</span>
              </Link>

              {/* Locations with Submenu */}
              <div className='relative'>
                <CustomPopover
                  // arrow={false}
                  open={submenuOpen}
                  placement='bottom'
                  content={
                    <div className='mt-2 w-60'>
                      {locationsMenuMock.map((location, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            if (!pathName.includes('/locations')) {
                              router.push(`/locations#${location.key}`);
                            } else {
                              const element = document.getElementById(location.key);
                              if (element) {
                                const offset = -200; // Adjust this value to control the spacing above the element
                                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                                window.scrollTo({
                                  top: elementPosition + offset,
                                  behavior: 'smooth',
                                });
                              }
                            }
                            setSubmenuOpen(false);
                          }}
                          className='flex h-full cursor-pointer items-center py-2 text-[16px] font-medium text-gray-700 hover:bg-gray-100 hover:text-zelene-red'
                        >
                          {location?.titleMenu}
                        </div>
                      ))}
                    </div>
                  }
                  trigger={['click']}
                >
                  <button
                    className={clsx(
                      'flex items-center gap-1 px-3 hover:text-zelene-red',
                      pathName === '/locations' && activeStyle,
                    )}
                    onClick={toggleSubmenu}
                  >
                    <span className='!text-zele-red'>Locations</span>
                  </button>
                </CustomPopover>

                {/* Submenu */}
                {/* {submenuOpen && (
                  <div className='absolute left-0 mt-2 w-60 rounded-md bg-white shadow-lg'>
                    <ul className='my-1 py-2 text-[1rem]'>
                      {locationsMenuMock.map((location, index) => (
                        <li key={index}>
                          <Link
                            onClick={() => setSubmenuOpen(false)}
                            href={`/locations#${location.key}`}
                            className='block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-zelene-red'
                          >
                            {location?.titleMenu}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )} */}
              </div>

              <Link className={clsx('px-3', pathName.includes('/blog') && activeStyle)} href={'/blog'}>
                <span className='!text-zele-red hover:text-zelene-red'>Blog</span>
              </Link>

              <Link className={clsx('px-3', pathName === '/contact' && activeStyle)} href={'/contact'}>
                <span className='!text-zele-red hover:text-zelene-red'>Contact Us</span>
              </Link>

              <Link className={clsx('px-3', pathName === '/career' && activeStyle)} href={'/career'}>
                <span className='!text-zele-red hover:text-zelene-red'>Career</span>
              </Link>
            </div>
          </nav>

          {/* Cart Control */}
          <Drawer
            width={520}
            footer={
              listCart?.length > 0 ? (
                <div className='flex flex-1 justify-between'>
                  <div>
                    <div className='text-base font-medium leading-[1.125rem] text-zelene-dark-blue'>
                      Total:&nbsp;
                      <span className='font-hneu text-[1.125rem] font-bold text-zelene-red'>$ {totalPrice} </span>
                    </div>

                    <span className='text-sm font-medium leading-[1.125rem] text-zelene-dark-blue sm:text-base'>
                      ({totalServicesCount} Services - {totalAddonsCount} Add ons)
                    </span>
                  </div>

                  <div
                    data-track-id='step1_location'
                    className={clsx(
                      'inline-flex items-center justify-center', // Makes it look like a button
                      'rounded-full bg-zelene-yellow px-2 font-hneu text-lg text-white sm:px-12 sm:py-2',
                      'transition-colors duration-300 ease-in-out hover:bg-yellow-500 hover:text-zelene-blue', // Optional hover state
                    )}
                    onClick={() => {
                      router.push('/booking?isPaymentNow=true#top');
                      setCartOpen(false);
                    }}
                  >
                    <span className='mr-2'>Book Now</span>
                    {/* <Image alt="star footer" width={16} height={16} src={yellowStar} className="h-[1rem] w-[1rem]" /> */}
                  </div>
                </div>
              ) : (
                <Link
                  data-track-id='step1_location'
                  href='/booking#top'
                  className={clsx(
                    'inline-flex w-full items-center justify-center !self-end', // Makes it look like a button
                    'rounded-full bg-zelene-yellow px-12 py-2 font-hneu text-lg text-white',
                    'transition-colors duration-300 ease-in-out hover:bg-yellow-500 hover:text-zelene-blue', // Optional hover state
                  )}
                  onClick={() => setCartOpen(false)}
                >
                  <span className='mr-2'>Book Now</span>
                </Link>
              )
            }
            styles={{
              footer: {
                display: listCart?.length > 0 ? 'flex' : 'none',
              },
            }}
            title='Your cart'
            open={cartOpen}
            onClose={() => setCartOpen(false)}
          >
            {listCart?.length > 0 ? (
              <CartList
                listCart={listCart}
                onRemove={profile ? deleteCartMemberMutation.mutate : deleteCartGuestMutation.mutate}
              />
            ) : (
              // <div className='flex h-full flex-col items-center justify-center font-hneu text-[2rem] font-medium text-zelene-grey'>
              //   Cart is empty !
              //   <div className='relative h-[12.5rem] w-full'>
              //     <Image
              //       alt='empty cart'
              //       fill
              //       sizes='(max-width: 40rem) 80vw,20vw'
              //       className='object-contain'
              //       src={EmptyCart}
              //     />
              //   </div>
              // </div>
              <div className='flex h-full flex-1 flex-col items-center justify-center gap-6 p-6'>
                <div className='relative'>
                  <Image src={CartICon} alt='cart icon' width={64} height={64} className='size-16 object-contain' />
                  <div className='absolute bottom-[calc(100%-0.4375rem)] left-[calc(100%-0.625rem)] size-[1.125rem] items-center justify-center rounded-full bg-zelene-red text-center text-xs text-white'>
                    0
                  </div>
                </div>
                <div className='max-w-[20.3125rem]'>
                  <div className="font-['Helvetica Neue'] text-center text-xl font-medium text-zelene-blue">
                    Your cart is empty!
                  </div>
                  <div className="font-['Helvetica Neue'] text-center text-sm font-normal leading-[1.0625rem] text-zelene-blue-secon">
                    Treat yourself to a relaxing spa experience, explore our services and indulge in self-care today.
                  </div>
                </div>

                <LoadingButton
                  className='h-11 rounded-[2.75rem] bg-[#1d3f75] px-10 font-hneu text-base font-medium leading-tight text-white hover:opacity-95 sm:px-12'
                  variant={'none'}
                  onClick={() => {
                    router.push('/booking#top');
                    setCartOpen(false);
                  }}
                  data-track-id='step1_location'
                >
                  Book Now
                </LoadingButton>
              </div>
            )}
          </Drawer>

          {/* Menu control */}
          <Drawer
            styles={{
              body: { backgroundColor: '#FCDCAB41', padding: 0, zIndex: 50, paddingTop: 64 },
              header: {
                backgroundColor: '#FCDCAB41',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 17,
              },
            }}
            title={
              <div className='my-auto !flex min-h-10 flex-1 items-center !justify-end gap-2 pr-4'>
                <HomeAuthAction />
              </div>
            }
            width={'100vw'}
            onClose={() => setOpen(false)}
            open={open}
            placement='left'
          >
            <Menu setOpen={setOpen} />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default ClientHeaderV2;
