import(/* webpackMode: "eager", webpackExports: ["Button"] */ "__barrel_optimize__?names=Button!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/circel.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/Frame 13.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/Home/HomeChoosen.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/Home/HomeChoosenFooter.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/Home/HomeChoosenFooter.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/redStar.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/WhiteStar.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/yellowStar.png");
;
import(/* webpackMode: "eager" */ "/app/public/svgs/RedStarSVG.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/sections/Carousel/HomeServiceCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/sections/Header/ClientHeaderV2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/sections/HomePage/ContactSection/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/sections/HomePage/FAQ/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/sections/HomePage/HeroSection/CarouselSlider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/sections/HomePage/HeroSection/FloatingAction/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/sections/HomePage/MembershipSection/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/sections/HomePage/TestinomialSection/index.tsx");
