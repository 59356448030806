'use client';

import { Carousel } from 'antd';
import { useRef, useState } from 'react';
import HeroCarouselSlideV2 from './HeroCarouselSlideV2';

const CarouselSlider = () => {
  const ref: any = useRef();
  const [nextIndex, setNextIndex] = useState(0);

  const beforeChange = (prev: number, next: number) => {
    setNextIndex(next);
  };

  const rightClick = () => {
    ref?.current?.next();
  };

  const leftClick = () => {
    ref?.current?.prev();
  };

  return (
    <Carousel
      ref={ref}
      beforeChange={beforeChange}
      dots={false}
      draggable
      initialSlide={0}
      className='!z-30'
      rootClassName='!z-30'
      autoplay={true}
      autoplaySpeed={10000}
      infinite={true}
      pauseOnHover={false}
    >
      <HeroCarouselSlideV2
        description='Shopping for someone else but not sure what to give? Give them the gift of choice with a Zelene gift card.'
        subtitle='Beauty And Wellness Haven'
        title='Giftcard is Available'
        linkCta='/gift'
        textCta='Gift Now'
        linkMobile='/videos/hero-section/Mobile-View.mp4'
        linkDesktop='/videos/hero-section/Destop-View.mp4'        
      />

      <HeroCarouselSlideV2
        description='Customize your Head Spa experience by selecting additional premium services designed to ensure your experience is suited specifically to you.'
        title='Featured Services'
        subtitle='Beauty And Wellness Haven'
        linkCta='/booking'
        textCta='Book Now'
        linkMobile='/videos/hero-section/Slide2-M.mp4'
        linkDesktop='/videos/hero-section/Slide2-D.mp4'
      />

      {/* <Slider2
        rightClick={rightClick}
        leftClick={leftClick}
        ref={ref}
        description={
          'Customize your Head Spa experience by selecting additional premium services designed to ensure your experience is suited specifically to you.'
        }
        textMain={'Featured'}
        textMainSecond={'Services'}
        imgMockup={'/images/Home/Slider3.webp'}
        imgSlider={'/images/Home/Slider3.png'}
        textSlider={'1 - 2'}
        textCta={'Book Now'}
        linkCta={'/booking'}
        videoUrl='/videos/zelenevideo.mp4'
      />

      <Slider2
        rightClick={rightClick}
        leftClick={leftClick}
        ref={ref}
        description={
          'Customize your Head Spa experience by selecting additional premium services designed to ensure your experience is suited specifically to you.'
        }
        textMain={'Featured'}
        textMainSecond={'Services'}
        imgMockup={'/images/Home/Slider3.webp'}
        imgSlider={'/images/Home/Slider3.png'}
        textSlider={'1 - 2'}
        textCta={'Book Now'}
        linkCta={'/booking'}
        videoUrl='/videos/hero-section/Destop-View.MOV'
      /> */}

      {/* <Slider2
        rightClick={rightClick}
        leftClick={leftClick}
        ref={ref}
        description={
          'Shopping for someone else but not sure what to give? Give them the gift of choice with a Zelene gift card.'
        }
        textMain={'Giftcard is'}
        textMainSecond={'Available'}
        imgMockup={'/images/Home/MainHero2.webp'}
        imgSlider={'/images/Home/MockupSlider2.webp'}
        textSlider={'2 - 2'}
        textCta={'Gift Now'}
        linkCta={'/gift'}
        classNameImgMockup='bg-[linear-gradient(333deg,_rgba(255,_255,_255,_0.00)_18.53%,_rgba(255,_255,_255,_0.30)_87.99%)]'
      /> */}
    </Carousel>
  );
};

export default CarouselSlider;
