'use client';

import { Tabs, TabsProps } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';
import GoogleIcon from '../../../../../public/images/Home/GoogleIcon.webp';
import MainTestimonial from '../../../../../public/images/testinomial-count.webp';
import VectorTesti from '../../../../../public/images/VectorTesti.png';
import AutoSlide from '../HeroSection/AutoSlide';

const TestinomialSection = () => {
  const [activeKey, setActiveKey] = React.useState(1);
  const activeStyle = 'flex p-3 items-center justify-center rounded-full border !border-red-900 transition-all';

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <div className={clsx(activeKey === 1 && activeStyle)}>
          <div className='flex h-[54px] w-[54px] items-center justify-center rounded-full bg-zelene-cream'>
            <Image
              src={GoogleIcon}
              width={48}
              height={48}
              className='h-[48px] w-[48px] object-contain'
              alt='customer-review'
            />
          </div>
        </div>
      ),

      children: (
        <div className='flex flex-col font-hneu text-base text-zelene-blue'>
          <p>10/10 Experience at Hera Head Spa!</p>
          <p>
            As someone in the beauty industry, I hold incredibly high standards for any service I receive, and Hera Head
            Spa exceeded every expectation. From the moment I walked in, I was met with a calming, serene environment
            that instantly made me feel like I was in the right place for some much-needed rejuvenation.
          </p>

          <p>
            The level of care, professionalism, and expertise from the team was beyond impressive. They truly understand
            not only the technical aspects of scalp and hair health but also how to deliver an experience that leaves
            you feeling completely restored. The products they use are top-tier, and the attention to detail was
            something I rarely see, even in my own field. Every step of the process felt luxurious, intentional, and
            perfectly tailored to my needs.
          </p>

          <p>
            I left with my scalp feeling invigorated and my hair looking and feeling its absolute best. Hera Head Spa
            offers a level of service that sets a new standard for the industry. If you're looking for a transformative
            experience, this is the place.
          </p>

          <p>
            As someone who knows what excellence looks like in beauty and wellness, I can confidently say Hera Head Spa
            is a 10/10 and worth every bit of praise!
          </p>

          <p className='font-hneu text-lg font-medium leading-[20px]'>Kari Patterson</p>
          {/* <span className='text-sm leading-[17px] text-[#A7A7A7]'>CEO & Founder</span> */}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className={clsx(activeKey === 2 && activeStyle)}>
          <div className='flex h-[54px] w-[54px] items-center justify-center rounded-full bg-zelene-cream'>
            <Image
              src={GoogleIcon}
              width={48}
              height={48}
              className='h-[48px] w-[48px] object-contain'
              alt='review comment'
            />
          </div>
        </div>
      ),
      children: (
        <div className='flex flex-col text-base text-zelene-blue'>
          <p>
            Wonderful experience with Eric and Daniel! Thank you both for your gentle and relaxing experience!
            Thoroughly enjoyed and will be back!
          </p>

          <p className='text-lg font-medium leading-[20px]'>Nancy Mijares</p>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div className={clsx(activeKey === 3 && activeStyle)}>
          <div className='flex h-[54px] w-[54px] items-center justify-center rounded-full bg-zelene-cream'>
            <Image
              src={GoogleIcon}
              width={48}
              height={48}
              className='h-[48px] w-[48px] object-contain'
              alt='review comment'
            />
          </div>
        </div>
      ),
      children: (
        <div className='flex flex-col text-base text-zelene-blue'>
          <p>
            Zelene Head Spa is absolutely incredible! As someone with 3c/4a curly hair, I can’t even explain how hard it
            is to find a place that truly understands and caters to my hair type—but they nailed it. I had the Luxe Curl
            treatment with Michael, and WOW—he was amazing. They started with a scalp analyzer machine to check the
            health of my scalp, which I’d never experienced before. From there, it was pure luxury. The steam eye mask
            was so relaxing, the deep-penetrating oil treatment left my scalp feeling like it got the care it deserved,
            and the shoulder and arm massage during the treatment was the cherry on top. I felt completely pampered from
            start to finish. What really stood out to me was how inclusive they are for all hair types. They clearly
            know how to treat curly hair with care, and they even have curly hair products available for you to use
            after the treatment to style your hair. It’s the little details like that that make all the difference. This
            new location in Frisco near Coit/Main is so convenient and beautifully set up. If you have curly hair (or
            any hair type, honestly) and want an experience that leaves you feeling relaxed, pampered, and cared for,
            Zelene is where you need to go. I already can’t wait for my next appointment!
          </p>

          <p className='text-lg font-medium leading-[20px]'>Kristi Moore</p>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className={clsx(activeKey === 4 && activeStyle)}>
          <div className='flex h-[54px] w-[54px] items-center justify-center rounded-full bg-zelene-cream'>
            <Image
              src={GoogleIcon}
              width={48}
              height={48}
              className='h-[48px] w-[48px] object-contain'
              alt='review comment'
            />
          </div>
        </div>
      ),
      children: (
        <div className='flex flex-col text-base text-zelene-blue'>
          <p>
            My visit to Zelene Head Spa was an absolutely rejuvenating experience. The treatment itself was incredible.
            The scalp massage was both gentle and invigorating. The therapist used high-quality oils and serums tailored
            to my needs, leaving my hair feeling nourished and very soft. What stood out most was the attention to
            detail and the professionalism of the staff. They took the time to assess my scalp condition and customize
            the treatment accordingly. It felt luxurious yet therapeutic, making it more than just a beauty treatment.
            By the end of the session my hair looked shinier, and I felt deeply relaxed. If you’re looking for a unique
            way to de-stress while also improving your hair health, I highly recommend Zelene Head Spa, I’ll definitely
            be returning!
          </p>

          <p className='text-lg font-medium leading-[20px]'>Jennifer Jackson</p>
        </div>
      ),
    },
    {
      key: '5',
      label: (
        <div className={clsx(activeKey === 5 && activeStyle)}>
          <div className='flex h-[54px] w-[54px] items-center justify-center rounded-full bg-zelene-cream'>
            <Image
              src={GoogleIcon}
              width={48}
              height={48}
              className='h-[48px] w-[48px] object-contain'
              alt='review comment'
            />
          </div>
        </div>
      ),
      children: (
        <div className='flex flex-col text-base text-zelene-blue'>
          <p>
            I cannot recommend Zelene Headspa enough! As a hairstylist, I carry a lot of tension in my back, and this
            experience was not only deeply relaxing but also helped work out some serious knots. From start to finish,
            everything was so soothing—the atmosphere, the treatments, and the care they put into every step. My
            technician, Alexa, was absolutely amazing. She made sure I was comfortable the entire time and customized
            the treatment to exactly what my scalp and hair needed. After getting all that buildup off my scalp, my hair
            feels incredibly soft and weightless, and my skin is SO hydrated. It’s like a full reset for my hair and
            body. If you’ve been thinking about booking, do it—you won’t regret it!
          </p>

          <p className='text-lg font-medium leading-[20px]'>Marvie Nunez</p>
        </div>
      ),
    },
  ];

  const onChange = (key: string) => {
    setActiveKey(+key);
  };

  return (
    <div className='relative flex flex-col overflow-hidden bg-[#FFFDF980] pb-0 pt-10 sm:py-[128px]'>
      <div className='absolute right-0 top-0 z-[-1] h-1/3 w-[min(676px,70%)]'>
        <Image alt='vector' src={VectorTesti} fill sizes='40vw' className='object-contain' />
      </div>

      <div className='mx-auto flex w-full flex-col px-6 *:flex-1 sm:w-[min(1512px,90%)] sm:px-0 lg:flex-row'>
        <div className='relative !mx-auto !flex max-h-[527px] min-h-[320px] w-full !flex-1 !items-center !justify-center md:h-[527px] md:w-[526px]'>
          <Image
            fill
            sizes='(min-width: 640px) 80vw,50vw'
            src={MainTestimonial}
            alt='MainTestimonial'
            className='max-h-[527px] object-contain sm:max-w-[526px]'
          />
        </div>

        <div className='flex flex-1 flex-col gap-1'>
          <div className='flex w-full items-center gap-4 pt-8 font-hneu text-[20px] !leading-[24px] text-zelene-blue sm:pt-0'>
            <div className='h-0.5 w-[93px] bg-[#1D3F75] sm:h-0.5'></div>
            <span className='text-base leading-tight sm:text-2xl'>Testimonials</span>
          </div>

          {/* <div className='max-w-[615px] font-prettywise text-[32px] font-semibold leading-[40px] text-zelene-blue sm:text-[64px] sm:leading-[80px]'> */}
          {/* <div className='w-full font-prettywise text-[40px] leading-[48px] text-zelene-blue sm:max-w-[452px]'> */}
          <div className='font-prettywise font-medium leading-[40px] text-zelene-blue sm:leading-tight'>
            <span className='w-full text-[32px] font-medium sm:!max-w-[615px] sm:text-[64px] sm:leading-[80px]'>
              What Our Customers <br className='sm:hidden' />
              Say About Us
            </span>
          </div>

          <Tabs translate='yes' defaultActiveKey='1' items={items} onChange={onChange} />
        </div>
      </div>

      <div className='relative mx-auto mb-4 mt-10 flex w-full max-w-[2040px] flex-col sm:mb-0'>
        {/* <div className='z-10 h-full w-full bg-tagline-1 bg-cover bg-center bg-no-repeat' />
        <div className='absolute h-full w-full bg-tagline-2 bg-cover bg-center bg-no-repeat' /> */}

        <div className='z-20 -translate-x-1 rotate-[-5deg]'>
          <AutoSlide backgroundColor='bg-zelene-blue' className='w-[110%]' />
        </div>
        <div className='z-10 -translate-x-1 rotate-[3deg]'>
          <AutoSlide backgroundColor='bg-zelene-heavy-cream' className='w-[110%]' />
        </div>
      </div>
    </div>
  );
};

export default TestinomialSection;
